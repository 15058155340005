import { atom } from "recoil";

import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
const currentUserInfoState = atom({
  key: "currentUserInfoState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const currentOnboardingStepState = atom({
  key: "currentOnboardingStepState", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const stripePersonInfo = atom({
  key: "stripePersonInfo", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const openingHoursState = atom({
  key: "openingHours", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const closingHoursState = atom({
  key: "closingHours", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const selectedFulfillmentModes = atom({
  key: "selectedFulfillmentModes", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const selectedSellerIcon = atom({
  key: "selectedSellerIcon", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const businessDetailsState = atom({
  key: "businessDetails", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const selectedSellerAttributes = atom({
  key: "selectedSellerAttributes", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const currentShoppingCart = atom({
  key: "currentShoppingCart", // unique ID (with respect to other atoms/selectors)
  default: { total: 0, itemCount: 0, cartItems: {}, businessId: 0 }, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const orderCheckoutDetails = atom({
  key: "Order Checkout Details",
  default: {
    Mode: "live",
    Order: {},
    Cart: [],
    PurchaseFromBID: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
export default currentUserInfoState;
